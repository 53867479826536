var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"上级名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'parent_department_id',
          {
            rules: [
              { required: false, message: '' },
            ],
          },
        ]),expression:"[\n          'parent_department_id',\n          {\n            rules: [\n              { required: false, message: '' },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.departmentTreeData,"placeholder":"请选择部门"}})],1),_c('a-form-item',{attrs:{"label":"部门名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]),expression:"['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"}],attrs:{"placeholder":"部门名称"}})],1),_c('a-form-item',{attrs:{"label":"备注","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]),expression:"['remark', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"}],attrs:{"rows":2,"placeholder":"备注"}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.buttonCol,false),[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1),_c('a-col',{attrs:{"span":"10"}},[_c('a-button',{on:{"click":_vm.handleGoBack}},[_vm._v("返回")])],1),_c('a-col',{attrs:{"span":"8"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }