<template>
  <div>
    <a-form :form="form">
      <a-form-item label="上级名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-tree-select
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="departmentTreeData"
          v-decorator="[
            'parent_department_id',
            {
              rules: [
                { required: false, message: '' },
              ],
            },
          ]"
          placeholder="请选择部门"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item label="部门名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          v-decorator="['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"
          placeholder="部门名称"/>
      </a-form-item>
      <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea v-decorator="['remark', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]" :rows="2" placeholder="备注"/>
      </a-form-item>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { oaDepartmentList, oaDepartmentUpdate } from '@/api/oa_department'
import debounce from 'lodash/debounce'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.departmentSearch = debounce(this.handleDepartmentSearch, 800)
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      departmentTreeData: []
    }
  },
  mounted () {
    this.handleDepartmentSearch(undefined)
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          oaDepartmentUpdate(values, this.id)
            .then((res) => {
              if (res.code === 1000) {
                this.handleGoBack()
              }
            })
        }
      })
    },
    handleDepartmentSearch (value) {
      oaDepartmentList({ name: value, page: 1, page_size: 99999 }).then((res) => {
        const result = res.data.entries || []
        this.departmentTreeData = this.buildTree(this.transformDepartments(result))
      }).finally(() => {
        this.$nextTick(() => {
          this.loadEditInfo(this.record)
        })
      })
    },
    transformDepartments (departments) {
      return departments.map(department => ({
        title: department.name,
        value: department.id,
        key: department.id,
        parent_department_id: department.parent_department_id,
        remark: department.remark
      }))
    },
    buildTree (departments) {
      const idToNodeMap = {}
      const tree = []
      // 初始化每个节点
      departments.forEach(department => {
        idToNodeMap[department.key] = { ...department, children: [] }
      })
      // 构建树状结构
      departments.forEach(department => {
        const parentId = department.parent_department_id
        if (parentId === -1) {
          tree.push(idToNodeMap[department.key])
        } else {
          idToNodeMap[parentId].children.push(idToNodeMap[department.key])
        }
      })
      return tree
    },
    handleDepartmentChange (value) {
      if (value === undefined) {
        this.handleDepartmentSearch(value)
      }
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        if (data.parent_department_id === -1) {
          data.parent_department_id = undefined
        }
        const formData = pick(data, ['id', 'parent_department_id', 'name', 'remark'])
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
