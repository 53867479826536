<template>
  <a-modal
    title="新建部门"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="上级名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-tree-select
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="departmentTreeData"
            v-decorator="[
              'parent_department_id',
              {
                rules: [
                  { required: false, message: '' },
                ],
              },
            ]"
            placeholder="请选择部门"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="部门名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['name', {rules: [{required: true, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]"
            placeholder="部门名称"/>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea v-decorator="['remark', {rules: [{required: false, message: '请输入'}, {max:128, message: '输入不符合长度限制'}]}]" :rows="2" placeholder="备注"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// 表单字段
import debounce from 'lodash/debounce'
import { oaDepartmentList } from '@/api/oa_department'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.departmentSearch = debounce(this.handleDepartmentSearch, 800)
    return {
      departmentTreeData: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  },
  created () {
    this.handleDepartmentSearch()
  },
  methods: {
    handleDepartmentSearch () {
      oaDepartmentList().then((res) => {
        const result = res.data.entries || []
        this.departmentTreeData = this.buildTree(this.transformDepartments(result))
      })
    },
    transformDepartments (departments) {
      return departments.map(department => ({
        title: department.name,
        value: department.id,
        key: department.id,
        parent_department_id: department.parent_department_id,
        remark: department.remark
      }))
    },
    buildTree (departments) {
      const idToNodeMap = {}
      const tree = []
      // 初始化每个节点
      departments.forEach(department => {
        idToNodeMap[department.key] = { ...department, children: [] }
      })
      // 构建树状结构
      departments.forEach(department => {
        const parentId = department.parent_department_id
        if (parentId === -1) {
          tree.push(idToNodeMap[department.key])
        } else {
          idToNodeMap[parentId].children.push(idToNodeMap[department.key])
        }
      })
      return tree
    },
    handleDepartmentChange (value) {
      if (value === undefined) {
        this.handleDepartmentSearch(value)
      }
    }
  }
}
</script>
